<template>
  <div>
    <div class="mt-5 bg-white border border-gray-100 rounded-xl">
      <div class="divide-y divide-gray-100">
        <div class="flex items-center p-3 gap-x-2">
          <IconHistory class="fill-primary"></IconHistory>
          <span>ประวัติการถูกสแกน</span>
        </div>
        <div v-for="(scanLog, index) in scanLogs.slice(0, 3)" :key="index" class="flex px-3 py-2 text-gray-400">
          <div>{{ index + 1 }}.</div>
          <div class="flex-1 text-center">{{ scanLog.createdDate }}</div>
        </div>
        <div v-show="scanLogs.length > 3" class="p-3 text-center">
          <a href="#" class="text-sm font-semibold text-primary plausible-event-name=Click+More+Scan+History" @click="showScanHistory = true">เพิ่มเติม</a>
        </div>
      </div>
    </div>

    <b-modal v-model="showScanHistory" :width="300" class="">
      <div class="bg-white border border-gray-100">
        <div class="divide-y divide-gray-100">
          <div class="flex items-center p-3 gap-x-2">
            <IconHistory class="fill-primary"></IconHistory>
            <span>ประวัติการถูกสแกน</span>
          </div>
          <div v-for="(scanLog, index) in scanLogs" :key="index" class="flex px-3 py-2 text-gray-400">
            <div>{{ index + 1 }}.</div>
            <div class="flex-1 text-center">{{ scanLog.createdDate }}</div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import IconHistory from './IconHistory.vue';

export default {
  props: {
    scanLogs: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      showScanHistory: false,
    };
  },
  computed: {
    isActive() {
      return this.showScanHistory ? "is-active" : "";
    }
  },
  components: { IconHistory }
}
</script>

<style lang="scss">
.modal .modal-content {
  border-radius: 15px;
}
</style>
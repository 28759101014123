<template>
  <div>
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>

    <PageNotFound v-if="showPageNotFound"></PageNotFound>

    <div v-else>
      <ImageCarousel :carousels="carousels" :companyId="company.id"></ImageCarousel>

      <div class="p-4 pt-5 product-info" style="height: auto;">
        <div class="flex items-center justify-between">
          <span class="text-2xl font-semibold">{{ product.name }}</span>
          <a v-if="product.shareUrl" :href="url" class="share">
            <img src="../assets/icon-share-facebook.png" />
          </a>
        </div>

        <ScanLogs :scanLogs="scanLogs"></ScanLogs>

        <div class="my-5">
          <p class="mb-3 title is-6">รายละเอียด</p>
          <p v-html="productDetail" class="whitespace-pre-line"></p>
        </div>

        <CopyCode :marginBottom="marginBottom"></CopyCode>
      </div>

      <BottomSheet ref="bottomSheet" :product="product" :questionTitle="questionTitle" :useId="useId"
        :qrcodeScanId="qrCodeScan.id" :code="code" :showQuestion="product.showQuestion && !isPopupMessageModalActice"
        :company="company">
      </BottomSheet>
    </div>

    <b-modal v-model="isPopupMessageModalActice" :width="300" :can-cancel="false">
      <div class="p-4 card has-text-centered">
        <IconVerify class="mx-auto fill-primary"></IconVerify>
        <p v-html="product.popupMessage" class="mx-4 whitespace-pre-line is-size-5 has-text-primary"></p>
        <p class="has-text-gray-light">”{{ product.name }}”</p>
        <b-button @click="isPopupMessageModalActice = false" type="is-primary is-rounded" class="mt-3" size="is-medium"
          expanded>ตกลง</b-button>
      </div>
    </b-modal>

    <b-modal v-model="isPopupWarningMessageModalActice" :width="300">
      <div class="p-4 text-center bg-white rounded-xl">
        <p class="whitespace-pre-line" v-html="product.warningMessage"></p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import "vue-popperjs/dist/vue-popper.css";
import PageNotFound from "./PageNotFound";
import ImageCarousel from "./ImageCarousel.vue";
import ScanLogs from "./ScanLogs.vue";
import CopyCode from "./CopyCode.vue";
import BottomSheet from "./BottomSheet.vue";
import { nextTick } from "process";
import IconVerify from "./IconVerify.vue";

export default {
  components: {
    PageNotFound,
    ImageCarousel,
    ScanLogs,
    CopyCode,
    BottomSheet,
    IconVerify,
  },
  data() {
    return {
      carousels: [],
      latitude: "",
      longitude: "",
      product: {},
      showPageNotFound: false,
      isLoading: false,
      isPopupMessageModalActice: false,
      isPopupWarningMessageModalActice: false,
      useId: null,
      qrCode: {},
      productDetail: "",
      scanLogs: [],
      qrCodeScan: {},
      questionTitle: null,
      marginBottom: 0,
      company: {},
      nonce: null,
      isLog: false,
      companyId: 0
    };
  },
  computed: {
    code() {
      return this.$route.params.code;
    },
    url() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.product.shareUrl}`;
    },
    qrcodeScanId() {
      return this.qrCodeScan?.id;
    },
  },
  async mounted() {
    // อินโนเวชั่น
    this.checkCompanyAndRedirect()
    await this.checkUrlAndRedirect();
    this.getLocation();
  },
  watch: {

    isPopupMessageModalActice(newValue) {
      if (this.product.hasPopupMessage) {
        if (!newValue) {
          if (this.product.showQuestion) {
            this.question();
          }
        }
      }
    },
  },
  methods: {
    async checkCompanyAndRedirect() {
      const { statusCode, data } = await this.$axios.get(
        `/code/${this.code}/company`
      );

      if (statusCode === 200) {
        this.companyId = data.companyId
      }

      if (this.companyId === 137) {
        window.location.replace(`https://uat-code-activate.foundcodecrm.com/${this.code}`);
      }

      if (this.companyId === 151) {
        window.location.replace(`https://code-activate.foundcodecrm.com/${this.code}`);
      }
    },
    async checkNonce() {
      if (!this.nonce) {
        this.nonce = Math.random()
          .toString(36)
          .substring(2, 15);
      }

      const { statusCode } = await this.$axios.get(
        `/qrCode/${this.code}/nonce/${this.nonce}`
      );

      if (statusCode === 201) {
        this.isLog = true;
      } else {
        this.isLog = false;
      }
    },
    async checkUrlAndRedirect() {
      this.isLoading = true;

      const hostname = window.location.hostname;
      if (
        hostname === "thavorn-mobile-web" ||
        hostname === "thavorn-mobile-web-prod" ||
        hostname === "thavorn-mobile-web-prod.web.app" ||
        hostname === "localhost"
      ) {
        let domainUrl = null;
        try {
          const { statusCode, data } = await this.$axios.get(
            `/domain/${this.code}`
          );
          if (statusCode === 200) {
            domainUrl = data.domainUrl;
          }
        } catch (error) {
          console.error(error);
        }

        if (domainUrl) {
          window.location.replace(`${domainUrl}/${this.code}`);
        }

        this.isLoading = false;
      }
    },
    async getLocation() {
      this.isLoading = true;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.showError
        );
      } else {
        await this.getProduct();
        console.log("Geolocation is not supported by this browser.");
      }
    },
    async showPosition(position) {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;

      this.getProduct();
    },
    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          this.getProduct();
          console.log("User denied the request for Geolocation.");
          break;
        case error.POSITION_UNAVAILABLE:
          this.getProduct();
          console.log("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          this.getProduct();
          console.log("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          this.getProduct();
          console.log("An unknown error occurred.");
          break;
      }
    },
    async getProduct() {
      try {
        await this.checkNonce();

        const { statusCode, data } = await this.$axios.get(
          `/scan/${this.code}?latitude=${this.latitude}&longtitude=${this.longitude}&isLog=${this.isLog}&nonce=${this.nonce}`
        );

        if (statusCode === 200) {
          this.product = data.qrCodeWithProduct.product;
          this.productDetail = this.product.detail;
          this.carousels = this.product.productImages;
          this.qrCode = data.qrCodeWithProduct;
          this.scanLogs = data.scanLogs;
          this.qrCodeScan = data.qrCodeScan;

          this.getCompany();

          if (!this.isEmpty(data.question)) {
            this.useId = data.question.questionaireUseId;

            this.getQuestion();
          }

          if (this.qrCode.notes != null) {
            this.isPopupMessageModalActice = false;
            this.product.showQuestion = false;
            this.isPopupWarningMessageModalActice = true;
          } else {
            this.isPopupMessageModalActice = this.product.hasPopupMessage
              ? true
              : false;
          }
        } else if (
          statusCode === 404 ||
          statusCode === 400 ||
          statusCode === 500
        ) {
          this.showPageNotFound = true;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;

        nextTick(() => {
          setTimeout(() => {
            this.marginBottom = this.$refs.bottomSheet.$el.clientHeight;
            console.log(this.$refs.bottomSheet.$el.clientHeight);
          }, 500);
        });
      }
    },
    async getCompany() {
      const { statusCode, data } = await this.$axios.get(
        `/crm/code/${this.code}/company`
      );

      if (statusCode === 200) {
        this.company = data;
        document.documentElement.style.setProperty(
          "--color-primary",
          this.company.color
        );
      }
    },
    async getQuestion() {
      const { statusCode, data } = await this.$axios.get(
        `/question/use/${this.useId}`
      );

      if (statusCode === 200) {
        this.questionTitle = data.name;
      }
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    alertOverLimit(warningMessage) {
      setTimeout(function () {
        alert(`${warningMessage}`);
      }, 350);
    },
    question() {
      this.$router.push(
        `/${this.code}/company/${this.product.companyId}/brand/${this.product.brandId}/product/${this.product.id}/questionnaire/${this.useId}/${this.qrCodeScan.id}`
      );
    },
  },
};
</script>

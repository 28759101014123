<template>
  <div class="app">
    <div class="header p-4">
      <p class="has-text-white has-text-centered is-size-5">ทำแบบสอบถาม</p>
    </div>

    <div class="px-5 thx">
      <img src="../assets/done.png" class="my-1" />
      <p class="my-1 has-text-weight-bold has-text-black">
        ขอบคุณสำหรับความร่วมมือในการตอบแบบสอบถาม
      </p>
      <p class="my-1 is-size-7 mb-6">
        คุณมีสิทธิ์ลุ้นรับของรางวัลมากมาย กับกิจกรรมสุดฮอท
      </p>

      <b-button
        tag="a"
        :href="product.luckyDrawUrl"
        type="is-primary"
        class="mt-6 is-rounded"
        size="is-medium"
        expanded
        v-show="product.hasLuckyDraw"
        >ซื้อสินค้า/สะสมคะแนน</b-button
      >
      <b-button
        tag="a"
        type="is-primary"
        class="mt-6 is-rounded"
        size="is-medium"
        expanded
        @click="share"
        >แชร์</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      product: {},
    };
  },
  computed: {
    code() {
      return this.$route.params.code;
    },
  },
  mounted() {
    this.getQrcode();
  },
  methods: {
    async getQrcode() {
      try {
        const { statusCode, data } = await this.$axios.get(
          `/getQrcode/${this.code}`
        );

        if (statusCode == 200) {
          this.product = data.product;
        }
      } catch (error) {
        console.error(error);
      }
    },
    share() {
      if (navigator.share) {
        if (this.product.shareUrl) {
          navigator.share({
            url: this.product.shareUrl,
          });
        } else {
          navigator.share({
            url: `https://${window.location.hostname}/${this.code}`,
          });
        }
      } else {
        console.log("The native share feature is not implemented");
      }
    },
  },
};
</script>

<style>
.thx {
  height: 90%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: center;
  position: absolute;
  width: 100%;
}
</style>
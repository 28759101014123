import Vue from "vue";
import {
  Carousel,
  Button,
  Loading,
  Modal,
  Radio,
  Input,
  Icon,
  Checkbox,
} from "buefy";
import App from "./App.vue";
import axios from "./axios";
import router from "./router";

import "buefy/dist/buefy.css";
import "./assets/main.scss";

// import VConsole from "vconsole";
import VueGeolocation from "vue-browser-geolocation";

Vue.prototype.$axios = axios;

Vue.use(Carousel);
Vue.use(Button);
Vue.use(Loading);
Vue.use(Icon);
Vue.use(Modal);
Vue.use(Radio);
Vue.use(Input);
Vue.use(Checkbox);

Vue.use(VueGeolocation);

// new VConsole();

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div class="flex items-center justify-center w-full min-h-screen">
    <div class="flex flex-col items-center justify-center gap-4 px-4 py-12 bg-white rounded-lg shadow-lg w-96 xl:w-120">
      <h1 class="text-2xl font-bold text-black uppercase xl:text-3xl">Verify your code</h1>
      <small class="text-center">กรุณาระบุรหัสที่ท่านต้องการตรวจสอบว่าเป็นของแท้หรือไม่ <br />โดยการกรอกรหัส 12
        หลักด้านล่าง</small>

      <input v-model="code" :maxlength="codeLength"
        class="w-full py-3 text-lg font-semibold text-center border border-gray-200 rounded-lg"
        placeholder="กรอกรหัสเช่น X14FU134L941" type="text" @keydown.enter="verifyCode" />

      <div class="flex items-center h-10">
        <b-button v-if="codeValid === null" :disabled="!canSubmit" @click="verifyCode" :loading="isLoading"
          class="font-semibold" type="is-info">ตรวจสอบรหัส</b-button>

        <span v-else-if="codeValid" class="text-lg font-semibold text-green-400"><b-icon icon="check" size="is-small"
            type="is-success" />
          รหัสถูกต้อง</span>

        <span v-else class="text-lg font-semibold text-red-500"><b-icon icon="close" size="is-small" type="is-danger" />
          ไม่พบรหัสนี้ในระบบ</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isLoading: false,
      code: '',
      codeLength: 12,
      codeValid: null,
    };
  },
  computed: {
    canSubmit() {
      return this.code.length === this.codeLength;
    },
  },
  methods: {
    async verifyCode() {
      if (!this.canSubmit || this.codeValid !== null) return;

      this.isLoading = true;

      const response = await this.$axios.get(`/qrCode/${this.code}/exists`)

      this.codeValid = response.statusCode === 200;

      this.isLoading = false;
    },
  },
};
</script>

<template>
  <div class="py-6 bg-samsung-browser">
    <div class="container is-fluid">
      <img src="../assets/line.png" class="mx-auto" alt="Samsung Browser" />
      <p class="mt-5 text-center title is-5">กรุณาให้อนุญาตในการเปิดแอป LINE</p>
      <p class="pt-3 text-center subtitle is-6">ในครั้งแรก ระบบสะสมคะแนนจำเป็นต้องสามารถเปิด
        แอป Line ได้จาก Default Browser ของคุณ</p>
    </div>
    <hr />
    <div class="container is-fluid">
      <p class="title is-6">วิธีทำตามขั้นตอนง่ายๆ</p>
      <ul class="list-decimal list-inside text-slate-900 dark:text-slate-200">
        <li>เปิดแอป ‘Samsung Internet’</li>
        <li>กดเข้าเมนูย่อย (ไอคอนสามขีด) ที่อยู่ทางด้านขวาล่างของจอ</li>
        <li>กดที่เมนู ‘ตั้งค่า’</li>
        <li>เลื่อนลงหาเมนูที่ชื่อ ‘คุณสมบัติที่มีประโยชน์’</li>
        <li>กดเปิดอนุญาตที่เมนู ‘เปิดลิงก์ในแอปอื่นๆ’</li>
      </ul>

      <p class="pt-6 text-center subtitle is-6">หากคุณทำตามขั้นตอนข้างต้นแล้ว</p>
      <b-button @click="collectPoint" type="is-primary" class="mt-2" size="is-medium"
        expanded>ลองใหม่อีกครั้ง</b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      company: {},
      latitude: "",
      longitude: "",
    }
  },
  computed: {
    code() {
      return this.$route.params.code;
    },
    lineUrl() {
      return `https://liff.line.me/${this.company.liffId}?companyId=${this.company.id}&code=${this.code}`
    }
  },
  mounted() {
    this.getProduct();
  },
  methods: {
    async getProduct() {
      try {
        const { statusCode, data } = await this.$axios.get(
          `/scan/${this.code}?latitude=${this.latitude}&longtitude=${this.longitude}&isLog=true`
        );

        if (statusCode === 200) {
          this.product = data.qrCodeWithProduct.product;

          this.getCompany()
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getCompany() {
      const { statusCode, data } = await this.$axios.get(
        `/crm/code/${this.code}/company`
      );

      if (statusCode === 200) {
        this.company = data
      }
    },
    collectPoint() {
      document.cookie = "allow=true";
      location.href = this.lineUrl;
    }
  },
}
</script>

<style lang="scss" scoped>
.bg-samsung-browser {
  background-color: #f1f2f6;
  height: 100vh;
}

p,
li {
  color: #8e8f93;
}

.mx-auto {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.text-center {
  text-align: center;
}

.header {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
}

.sub-header {
  font-size: 14px;
}

hr {
  border-color: #fff;
  border-style: dashed;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  background-color: #f1f2f6;
}

.list-decimal {
  list-style-type: decimal;
  margin-top: -14px;
}

.list-inside {
  list-style-position: inside;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";

import ProductInfo from "./components/ProductInfo";
import ProductInfoFinish from "./components/ProductInfoFinish";
import Question from "./components/Question";
import QuestionFinish from "./components/QuestionFinish";
import SamsungBrowser from "./components/SamsungBrowser";
import VerifyCode from "./components/VerifyCode";
import SelectCampaign from "./components/salz/SelectCampaign.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/verify",
      component: VerifyCode,
    },
    {
      path: "/:code",
      component: ProductInfo,
    },
    {
      path:
        "/:code/company/:companyId/brand/:brandId/product/:productId/questionnaire/:userId/:qrCodeScanId",
      component: Question,
    },
    {
      path: "/:code/questionnaire",
      component: QuestionFinish,
    },
    {
      path: "/:code/finish",
      component: ProductInfoFinish,
    },
    {
      path: "/:code/samsung",
      component: SamsungBrowser,
    },
    {
      path: "/salz/select-campaign",
      component: SelectCampaign,
    },
  ],
});

export default router;

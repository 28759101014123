<template>
  <div class="flex flex-col items-center justify-around w-full p-5 mt-5 bg-clipboard" :style="`margin-bottom: ${marginBottom}px;`">
    <p class="font-semibold fade-text">Unique Code</p>
    <span class="text-3xl font-bold"> {{ this.code }}</span>
    <popper trigger="clickToOpen" :options="{
      placement: 'top',
      modifiers: { offset: { offset: '0,10px' } },
    }">
      <div class="popper">copied</div>
      <button slot="reference" class="copy-button plausible-event-name=Copy+Code">
        <img src="../assets/copy.svg" @click="getClipboard" />
      </button>
    </popper>
  </div>
</template>

<script>
import Popper from "vue-popperjs";

export default {
  props: {
    marginBottom: {
      type: Number,
      default: 0
    },
  },
  components: {
    popper: Popper,
  },
  computed: {
    code() {
      return this.$route.params.code;
    },
  },
  methods: {
    getClipboard() {
      navigator.clipboard.writeText(this.code);
    },
  },
}
</script>
import axios from "axios";

let instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  // baseURL: 'http://localhost:3000',
});

instance.interceptors.request.use(
  (request) => {
    return request;
  },
  function(error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  function(error) {
    console.error(error);
    return Promise.reject(error);
  }
);

export default instance;

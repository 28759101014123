<template>
  <b-carousel indicator-mode="click" :indicator-inside="isSalz" indicator-position="is-bottom" indicator-style="is-dots"
    ref="carousel">
    <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
      <img :src="carousel.image" class="image-product" />
      <p v-show="isSalz" class="paging">{{ i + 1 }}/{{ carousels.length }}</p>
    </b-carousel-item>
  </b-carousel>
</template>

<script>
export default {
  props: {
    carousels: {
      type: Array,
      default: () => []
    },
    companyId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isSalz() {
      return this.companyId === 154 || this.companyId === 157 ? false : true
    }
  },
  watch: {
    carousels() {
      if (this.$refs.carousel) {
        this.$refs.carousel.activeChild = 0;
      }
    }
  },
}
</script>

<style lang="scss">
.image-product {
  position: relative;
  text-align: center;
  width: inherit;
}
</style>
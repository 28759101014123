<template>
  <div class="app question">
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>

    <div class="p-4 header">
      <p class="has-text-white has-text-centered is-size-5">{{ question.title }}</p>
    </div>

    <div class="p-4">
      <div class="title-detail">
        <p>{{ question.detail }}</p>
      </div>

      <div v-for="topic in topics" :key="topic.id">
        <div class="p-4 my-3 topic">
          <p style="font-size: 20px">{{ topic.order }}. {{ topic.name }}</p>
        </div>

        <div v-if="topic.type === 'TEXT'">
          <b-input v-model="topic.answer"></b-input>
        </div>

        <div class="choices" v-for="(choice, index) in topic.questionaireChoices" :key="index">
          <div v-if="topic.type === 'SINGLE'">
            <b-radio v-model="topic.answer" :name="choice.name" :native-value="choice.name">
              <p style="font-weight: normal">{{ choice.name }}</p>
            </b-radio>
          </div>

          <div v-if="topic.type === 'MULTI'">
            <b-checkbox v-model="topic.answer" :name="choice.name" :native-value="choice.name">
              <p style="font-weight: normal">{{ choice.name }}</p>
            </b-checkbox>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-field>
          <b-checkbox v-model="pdpa" name="pdpa">
            <p>ยอมรับ
              <a href="https://pdpa.pro/policies/view/th/QuGMzMzZbQxNyCruaSY4iYSw" target="_blank">เงื่อนไขการการให้บริการ
                และ
                นโยบายความเป็นส่วนตัว</a>
            </p>
          </b-checkbox>
        </b-field>
      </div>

      <b-button @click="sendQuestion()" type="is-primary" class="mt-6 is-rounded" size="is-medium" expanded
        :disabled="isSendQuestionDisabled">ส่งคำตอบ</b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      question: {},
      topics: [],
      pdpa: false
    };
  },
  computed: {
    useId() {
      return this.$route.params.userId;
    },
    companyId() {
      return this.$route.params.companyId;
    },
    brandId() {
      return this.$route.params.brandId;
    },
    productId() {
      return this.$route.params.productId;
    },
    code() {
      return this.$route.params.code;
    },
    qrCodeScanId() {
      return this.$route.params.qrCodeScanId
    },
    isSendQuestionDisabled() {
      return this.topics.some(topic => {
        if (!topic.answer) return true;
        return topic.answer.length === 0
      }) || this.pdpa === false
    }
  },
  mounted() {
    this.getQuestion();
  },
  methods: {
    async getQuestion() {
      this.isLoading = true;
      try {
        const { statusCode, data } = await this.$axios.get(
          `/question/use/${this.useId}`
        );

        if (statusCode === 200) {
          data.questionaires[0].questionaireTopics.forEach(
            (question, index) => {
              question.order = index + 1;
              question.answer = question.type == "MULTI" ? [] : null;
            }
          );

          this.question = data.questionaires[0];
          this.topics = this.question.questionaireTopics;
        }

        this.getCompany();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getCompany() {
      const { statusCode, data } = await this.$axios.get(
        `/crm/code/${this.code}/company`
      );

      if (statusCode === 200) {
        document.documentElement.style.setProperty("--color-primary", data.color)
      }
    },
    async sendQuestion() {
      const questionAnswers = this.topics.map((topic) => {
        return { question: topic.name, answer: topic.answer.toString() };
      });

      const req = {
        companyId: this.companyId,
        brandId: this.brandId,
        productId: this.productId,
        qrCodeCode: this.code,
        title: this.question.title,
        detail: this.question.detail,
        questionAnswers: questionAnswers,
        qrCodeScanId: this.qrCodeScanId,
        pdpa: this.pdpa
      };

      try {
        const { statusCode } = await this.$axios.post(
          `/answer/`, req
        );
        if (statusCode == 201) {
          this.$router.push(`/${this.code}/finish`)
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: var(--color-primary);
}

.question {
  p {
    font-size: 18px;
    font-weight: 600;
    color: #101010;
  }
}

.topic {
  border-radius: 12px;
  background-color: #d3dce624;
}
</style>
<template>
  <div>
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>

    <PageNotFound v-if="showPageNotFound"></PageNotFound>

    <div v-else>
      <ImageCarousel :carousels="carousels"></ImageCarousel>
      <div class="p-4 pt-5 product-info" style="height: auto;">
        <div class="flex items-center justify-between">
          <span class="text-2xl font-semibold">{{ product.name }}</span>
          <a v-if="product.shareUrl" :href="url" class="share">
            <img src="../assets/icon-share-facebook.png" />
          </a>
        </div>

        <ScanLogs :scanLogs="scanLogs"></ScanLogs>

        <div class="my-5">
          <p class="mb-3 title is-6">รายละเอียด</p>
          <p v-html="this.productDetail"></p>
        </div>

        <CopyCode :marginBottom="marginBottom"></CopyCode>
      </div>

      <BottomSheet ref="bottomSheet" :product="product" questionTitle="" :useId="null" :qrcodeScanId="null" :code="code"
        :showQuestion="false" :company="company"></BottomSheet>
    </div>

    <b-modal v-model="isPopupThankMessageModalActive" :width="300">
      <div class="p-4 text-center bg-white rounded-xl">
        <p class="">{{ product.thankMessage }}</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import "vue-popperjs/dist/vue-popper.css";
import PageNotFound from "./PageNotFound";
import ImageCarousel from "./ImageCarousel.vue";
import ScanLogs from "./ScanLogs.vue";
import CopyCode from "./CopyCode.vue";
import BottomSheet from "./BottomSheet.vue";
import { nextTick } from "process";

export default {
  components: {
    PageNotFound,
    ImageCarousel,
    ScanLogs,
    CopyCode,
    BottomSheet
  },
  data() {
    return {
      carousels: [],
      latitude: "",
      longitude: "",
      product: {},
      showPageNotFound: false,
      isLoading: false,
      isPopupThankMessageModalActive: false,
      useId: null,
      qrCode: {},
      productDetail: "",
      scanLogs: [],
      marginBottom: 0,
      company: {}
    };
  },
  computed: {
    code() {
      return this.$route.params.code;
    },
    url() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.product.shareUrl}`;
    },
    lineUrl() {
      return `https://liff.line.me/${this.company.liffId}?companyId=${this.company.id}&code=${this.code}`
    }
  },
  mounted() {
    this.getLocation();
  },
  methods: {
    async getLocation() {
      this.isLoading = true;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.showError
        );
      } else {
        await this.getProduct();
        console.log("Geolocation is not supported by this browser.");
      }
    },
    async getClipboard() {
      navigator.clipboard.writeText(this.code);
    },
    async showPosition(position) {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;

      this.getProduct();
    },
    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          this.getProduct();
          console.log("User denied the request for Geolocation.");
          break;
        case error.POSITION_UNAVAILABLE:
          this.getProduct();
          console.log("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          this.getProduct();
          console.log("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          this.getProduct();
          console.log("An unknown error occurred.");
          break;
      }
    },
    async getProduct() {
      this.isLoading = true;
      try {
        const { statusCode, data } = await this.$axios.get(
          `/scan/${this.code}?latitude=${this.latitude}&longtitude=${this.longitude}&isLog=false`
        );

        if (statusCode === 200) {
          this.product = data.qrCodeWithProduct.product;
          this.productDetail = this.product.detail.split(",").join("<br />");
          this.carousels = this.product.productImages;
          this.qrCode = data.qrCodeWithProduct;
          this.scanLogs = data.scanLogs;

          this.getCompany()

          if (this.$refs["carousel"]) {
            this.$refs["carousel"].activeChild = 0;
          }

        } else if (statusCode === 404 || statusCode === 400 || statusCode === 500) {
          this.showPageNotFound = true;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;

        if (this.product.thankMessage) {
          this.isPopupThankMessageModalActive = true;
        }

        nextTick(() => {
          setTimeout(() => {
            this.marginBottom = this.$refs.bottomSheet.$el.clientHeight
          }, 500)
        })

      }
    },
    async getCompany() {
      const { statusCode, data } = await this.$axios.get(
        `/crm/code/${this.code}/company`
      );

      if (statusCode === 200) {
        this.company = data;
        document.documentElement.style.setProperty(
          "--color-primary",
          this.company.color
        );
      }
    },
  },
  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  },
  alertOverLimit(warningMessage) {
    setTimeout(function () {
      alert(`${warningMessage}`);
    }, 350);
  },
  question() {
    this.$router.push(
      `/${this.code}/company/${this.product.companyId}/brand/${this.product.brandId}/product/${this.product.id}/questionnaire/${this.useId}`
    );
  },
};
</script>

<template>
  <div class="px-5 not-found">
    <img src="../assets/empty-state.png" class="my-1" />
      <p class="my-1 has-text-weight-bold has-text-black">ไม่พบข้อมูล</p>
      <p class="my-1 is-size-7">
        ไม่พบข้อมูลเนื่องจาก QR Code ยังไม่เปิดใช้งาน หรือ ถูกระงับการใช้งาน
      </p>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>